import { CSSProperties } from 'react';
import Carousel from 'react-material-ui-carousel'
import { useIsMobileView } from '../../utils/responsive';
import { Box } from '@mui/material';
import { getPrimaryColorByAccountId, styleConstants } from '../../utils/styleConstants';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';

interface RaffleImagesProps {
    images: string[]
}

export function RaffleImages({ images }: RaffleImagesProps)
{

    const accountId = useAppSelector(
        ({ account }: RootState) => account.id,
      );

    const isMobile = useIsMobileView();

    const sx: CSSProperties = {
        width: isMobile ? '100%': '600px',
        height: isMobile ? '466px': '90vh',
        display: 'flex',
        borderRadius: 20,
        border: `2px solid ${getPrimaryColorByAccountId(accountId)}`,
    }
    return (
        <Box pb={2}>
        <Carousel indicators={false} height={isMobile ? '460px': '90vh'} sx={{ paddingX: 2, paddingY: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
                images.map( (imageUrl, i) => 
                    <img key={imageUrl} alt={`${i}`} src={imageUrl} style={sx}></img> 
                )
            }
        </Carousel>
        </Box>
    )
}
