import { RaffleImages } from "../raffle/images";
import { Grid, Typography } from "@mui/material";
import RaffleTickets from "../raffle/tickets";
import { IRaffle } from "../../store/interfaces/IRaffle";
import { useIsMobileView } from "../../utils/responsive";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";
import TopPurchasers from "../raffle/topPurchasersPublic";
import { useGetTopPurchasersQuery } from "../../store/api";
import { getPrimaryColorByAccountId } from "../../utils/styleConstants";

interface ActiveRaffleProps {
  activeRaffle: IRaffle;
}

export const ActiveRaffle = ({ activeRaffle }: ActiveRaffleProps) => {

  const isMobile = useIsMobileView();
  const accountInformation = useAppSelector(({ account }: RootState) => account);

  const { data: topPurchasersFounded } = useGetTopPurchasersQuery(activeRaffle.id, {
    skip: activeRaffle.id === '',
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  return (
    <Grid container>
      <Grid item xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 2}>
        <Grid item  xs={12} xl={12} lg={12} md={12} sm={12} padding={isMobile ? 0 : 1}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <Typography
                  variant="h6"
                  component="div"
                  textAlign={"center"}
                >
                  <img
                    src={accountInformation.logo}
                    width={200}
                    height={200}
                    style={{ borderRadius: 20 }}
                    alt=""
                  />
                </Typography>
            </Grid>
            <Typography variant="h2" fontWeight={"bold"} textAlign={"center"}>
              {activeRaffle.name}
            </Typography>
        </Grid>
        <Grid pb={2}>
          <RaffleImages images={activeRaffle.awards.flatMap(element => element.images)}></RaffleImages>
        </Grid>
        <Grid>
          {topPurchasersFounded && activeRaffle.type === 'raffle' && accountInformation.id !== 'oscar-alarcon' && <TopPurchasers topPurchasers={topPurchasersFounded}></TopPurchasers>}
        </Grid>
        <Grid item  xs={12} xl={12} lg={12} sm={12} paddingTop={2}>
          <RaffleTickets raffleId={activeRaffle.id} limit={activeRaffle.limit} ticketPriceUSD={activeRaffle.ticketPriceUSD} ticketPrice={activeRaffle.ticketPrice} availablePaymentMethods={activeRaffle.availablePaymentMethods} raffleType={activeRaffle.type} />
        </Grid>
        <Grid item  xs={12} xl={12} lg={12} sm={12} p={4}>
          { accountInformation.id === 'maikel-dominguez' && <Typography variant="h4" fontFamily={"fantasy"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"}>
            Juan 13:7
          </Typography> }
          { accountInformation.id === 'maikel-jaimez' && <Typography variant="h4" fontFamily={"fantasy"} fontWeight={"bold"} color={getPrimaryColorByAccountId(accountInformation.id)} fontStyle={"italic"}>
            Somos Bendecidos Para Bendecir
          </Typography> }
        </Grid>
      </Grid>
    </Grid>
  );
};
