import { Box, Card, CardContent, Chip, FormControl, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import { getPrimaryColorByAccountId, getThemeByAccountId, styleConstants } from "../../../utils/styleConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types/rootState";

interface DesktopTicketsProps {
    ticketQuantity: number | null;
    sumTicketQuantity: (ticket: number) => void;
    handleChangeQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
    additions: Array<number>,
  }
  
export const MobileTickets: React.FC<DesktopTicketsProps> = ({ ticketQuantity,  handleChangeQuantity,  sumTicketQuantity, additions }) => {
    const accountId = useSelector(({ account }: RootState) => account.id);
    return (
      <Box sx={{ width: '98%' }}>
        <FormControl sx={{ m: 1, width: "100%" }}>
        <OutlinedInput
          type="number"
          autoFocus
          value={ticketQuantity ?? ""}
          onChange={handleChangeQuantity}
          startAdornment={
            <InputAdornment position="start">
              <Chip variant="outlined" label="Tickets" color="primary" />
            </InputAdornment>
          }
        />
        </FormControl>
          <Box width={"100%"} px={1}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              {additions.map((addition: number) => (
                <Card
                  key={addition}
                  sx={{
                    cursor: "pointer",
                    border: addition !== 20 ? "1px solid secondary.main" : "1px solid green",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    backgroundColor: `${getPrimaryColorByAccountId(accountId)}`,
                  }}
                  onClick={() => sumTicketQuantity(addition)}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, color: `${getThemeByAccountId(accountId) === 'dark' ? '#000 !important' : '#FFF !important' }`, }}
                    >
                      {`+${addition}`}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Stack>

          </Box>
      </Box>
    );
}